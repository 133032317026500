import React, { useState } from "react";
import { Checkbox } from "@material-ui/core";
import { Text, Avatar } from "@paro.io/base-ui";
import { Spinner } from "react-bootstrap";
import { useSnackbar } from "notistack";
import { Rating } from "@material-ui/lab";

export const SimplifiedAiResultCard = ({
  freelancer,
  onViewReview,
  cart,
  setCart,
  stage3,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isAddCartLoading, setAddCartLoading] = useState(false);

  const onChangeHandler = async (freelancer) => {
    setAddCartLoading(true);
    if (!cart.some((c) => c.id === freelancer.id)) {
      setCart((curr) => {
        return [...curr, { ...freelancer, flID: freelancer.id }];
      });

      sessionStorage.setItem(
        "CartFreelancers",
        JSON.stringify([...cart, { ...freelancer, flID: freelancer.id }])
      );

      enqueueSnackbar(`Added ${freelancer.name}  to Cart!`, {
        variant: "success",
      });
    } else {
      setCart((currentState) =>
        currentState.filter((c) => c.id !== freelancer.id)
      );
      const currentCart =
        JSON.parse(sessionStorage.getItem("CartFreelancers")) || [];
      const updatedCart = currentCart.filter((c) => c.id !== freelancer.id);
      sessionStorage.setItem("CartFreelancers", JSON.stringify(updatedCart));

      enqueueSnackbar(`Removed ${freelancer.name} from Cart!`, {
        variant: "warning",
      });
    }
    setAddCartLoading(false);
  };

  return (
    <li className="py-3">
      <div className="flex items-center gap-2">
        {isAddCartLoading ? (
          <Spinner
            animation="grow"
            size="sm"
            className="text-success ml-3 mr-3.5"
          />
        ) : (
          <Checkbox
            checked={cart.some((c) => c.id === freelancer.id)}
            onChange={(e) => {
              onChangeHandler({
                id: freelancer.id,
                name: freelancer.FL_Name,
                serviceLine: freelancer.FL_Service,
                imageUrl: freelancer.imageUrl,
              });
            }}
            inputProps={{ "aria-label": "checkbox" }}
          />
        )}
        <Avatar name="Avatar Name" size="md" src={freelancer.imageUrl} />
        <div className="flex flex-col gap-0 ml-2">
          <div
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_PARO_APP_URL}/internal/expert-profile/${freelancer?.id}`,
                "_blank"
              );
            }}
            onMouseOver={() => {}}
          >
            <Text
              size="sm"
              className="p-0 font-bold m-0 cursor-pointer hover:underline"
            >
              {freelancer?.FL_Name || freelancer?.name}
            </Text>
          </div>
          <Text size="sm" className="p-0 text-xs">
            {freelancer?.FL_Service || freelancer?.serviceLine}
          </Text>
          {!stage3 && (
            <div
              className={`flex items-center gap-2 ${
                freelancer.overallRatingAvg > 0
                  ? "cursor-pointer"
                  : "cursor-not-allowed"
              }`}
              onClick={() => {
                if (freelancer.overallRatingAvg > 0) {
                  onViewReview(freelancer);
                }
              }}
            >
              <Rating
                name="overallRatingAvg"
                defaultValue={freelancer.overallRatingAvg ?? 0}
                readOnly
                precision={0.1}
              />
              <Text>
                {freelancer.overallRatingAvg ?? 0}
                {freelancer.overallRatingAvg > 0 &&
                  `(${freelancer.ratings.length})`}
              </Text>
            </div>
          )}
          {freelancer?.legacyMetadata?.hourlyRate ? (
            <Text size="sm" className="p-0 font-bold m-0 text-danger-dark">
              ${freelancer?.legacyMetadata?.hourlyRate} / hour
            </Text>
          ) : null}
        </div>
      </div>
    </li>
  );
};

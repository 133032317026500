import { useEffect, useState } from "react";
import { TextareaAutosize } from "@material-ui/core";
import { Button, Text } from "@paro.io/base-ui";
import { SimplifiedUM } from "../components/ClientEnhancement/SimplifiedUM";
import ConfirmProject from "../components/ClientEnhancement/ConfirmProject";

const Enhancement = () => {
  const [fieldText, setFieldText] = useState("");
  const [generatedSummary, setGeneratedSummary] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeStage, setActiveStage] = useState(0);

  useEffect(() => {
    if (activeStage === 0) {
      const data = sessionStorage.getItem("enh-proposal");
      if (data) {
        setFieldText(JSON.parse(data).fieldText);
        setGeneratedSummary(JSON.parse(data).generatedSummary);
      }
    }
  }, [activeStage]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://jmmeiy3gw7.execute-api.us-east-1.amazonaws.com/dev2/predictions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ text: fieldText }),
        }
      );
      const data = await response.json();
      setGeneratedSummary(data);
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  const handleUseThis = () => {
    setFieldText(generatedSummary["Proposal Description"]);
  };

  const handleNext = () => {
    sessionStorage.setItem(
      "enh-proposal",
      JSON.stringify({ fieldText, generatedSummary })
    );

    setActiveStage(activeStage + 1);
  };

  return (
    <div className="flex flex-row w-full bg-white h-full min-h-screen">
      <div className="flex flex-col w-1/2 justify-center items-center py-4">
        <img
          src="https://paro.ai/image/Paro_logo_black.svg"
          alt="logo"
          className="w-48 mb-6 mt-4"
        />
        <Text className="text-2xl py-2 font-normal">
          Let’s get to know your business
        </Text>
        <div className="w-full px-8">
          <hr className="w-full border-t-1 border-gray-200 mt-6" />
        </div>

        <div className="w-full px-8">
          {activeStage === 0 && (
            <div>
              <Text className="text-gray-600 py-1 mt-3">
                Describe the type of support you need, including relevant
                software, skills, knowledge, and desired start date.
              </Text>

              <TextareaAutosize
                value={fieldText}
                onChange={(e) => setFieldText(e.target.value)}
                className="w-full h-96 p-4 border border-gray-300 rounded mb-4"
                size="small"
                minRows={4}
                maxRows={6}
              />
              <div className="flex justify-end space-x-4 mb-8">
                <Button
                  onClick={handleSubmit}
                  label="generate"
                  size="md"
                  isLoading={loading}
                  disabled={!fieldText}
                />
                <Button
                  label="Save & next"
                  size="md"
                  onClick={handleNext}
                  disabled={!fieldText}
                />
              </div>
              {generatedSummary && (
                <div className="bg-green-50 p-4 rounded my-4">
                  <h3 className="text-xl font-semibold mb-2">
                    Generated Summary
                  </h3>
                  <div className="mb-2 whitespace-pre-wrap break-words">
                    <p>
                      <strong>Proposal Description:</strong>{" "}
                      {generatedSummary["Proposal Description"]}
                    </p>
                    <p>
                      <strong>Required Services:</strong>{" "}
                      {generatedSummary["Required Services"].join(", ")}
                    </p>
                    <p>
                      <strong>Required Skills:</strong>{" "}
                      {generatedSummary["Required Skills"].join(", ")}
                    </p>
                    <p>
                      <strong>Required Certifications:</strong>{" "}
                      {generatedSummary["Required Certifications"].join(", ") ||
                        "None"}
                    </p>
                    <p>
                      <strong>Required Software:</strong>{" "}
                      {generatedSummary["Required Software"]}
                    </p>
                    <p>
                      <strong>Team, Location, and Time Zones:</strong>{" "}
                      {generatedSummary["Team, Location, and Time Zones"]}
                    </p>
                  </div>
                  <Button
                    onClick={handleUseThis}
                    className="rounded text-white mt-4"
                    color="secondary"
                    label="Use This"
                    size="md"
                  />
                </div>
              )}
            </div>
          )}
          {activeStage === 1 && (
            <SimplifiedUM setActiveStage={setActiveStage} />
          )}
          {activeStage === 2 && (
            <ConfirmProject
              setActiveStage={setActiveStage}
              setGeneratedSummary={setGeneratedSummary}
              setFieldText={setFieldText}
            />
          )}
        </div>
        <div className="w-5/6 bg-gray-200 rounded-full h-2 mt-4">
          <div
            className="bg-gray-400 h-2 rounded-full transition-all duration-300 "
            style={{
              width: `${((activeStage + 1) / 3) * 100}%`,
            }}
          ></div>
        </div>
      </div>
      <div
        className="flex bg-primary w-1/2 p-8"
        style={{
          backgroundImage:
            "url(https://paro.ai/wp-content/uploads/2024/03/pattern-3-1.png)",
          backgroundPosition: "left top",
        }}
      >
        <div className="flex flex-col items-center pt-8">
          <Text className="text-white mb-8 text-center text-5xl w-4/5">
            Trusted finance talent from top institutions and companies.
          </Text>
          <img
            src="https://paro.ai/wp-content/uploads/2024/02/company-grp-min-2.png"
            alt="company"
            className="mt-4"
          />
        </div>
      </div>
    </div>
  );
};

export default Enhancement;

import { useEffect, useState } from "react";
import Sidebar from "../ClientEnhancement/SideBar";
import CustomSpinner from "../CustomSpinner";
import { getFreelancerDetailsFn } from "../../utils/platformService";
import { SimplifiedSearchResults } from "./SimplifiedSearchResults";
import { getAllFreelancerRatingsAndReviews } from "../../services/apiService";

export const SimplifiedUM = ({ setActiveStage }) => {
  const [loadingForm, setLoadingForm] = useState(true);
  const [formData, setFormData] = useState();
  const [freelancers, setFreelancers] = useState();
  const [loadingFls, setLoadingFls] = useState(false);

  useEffect(() => {
    const data = sessionStorage.getItem("enh-proposal");
    if (data) {
      setFormData(JSON.parse(data));
    }
    const freelancersData = sessionStorage.getItem("enh-freelancers");
    if (freelancersData) {
      setFreelancers(JSON.parse(freelancersData));
    }
    setLoadingForm(false);
  }, []);

  const getClientRatings = async (flID) => {
    const res = await getAllFreelancerRatingsAndReviews(flID);
    const ratings = res.data.data.getAllFreelancerRatingsAndReviews;

    const overallRatingSum = ratings.reduce(
      (sum, rating) => sum + rating.overallRating,
      0
    );

    const overallRatingAvg =
      ratings.length > 0 ? overallRatingSum / ratings.length : 0;

    return { ratings, overallRatingAvg };
  };

  const onRunMatch = async (payload) => {
    setLoadingFls(true);
    try {
      const response = await fetch(
        "https://dq7fke59ni.execute-api.us-east-1.amazonaws.com/dev3/proposal_demo",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const algoResponse = await response.json();

      algoResponse.recommendedFreeLancers = JSON.parse(
        algoResponse.recommendedFreeLancers
      )
        .sort((a, b) => b.score - a.score)
        .slice(0, 5)
        .map((fl) => ({ ...fl, flID: fl.freelancer_id }));

      const freelancers = await getFreelancerDetailsFn(algoResponse);

      for (let fl of freelancers) {
        const { ratings, overallRatingAvg } = await getClientRatings(fl.flID);
        fl.ratings = ratings;
        fl.overallRatingAvg = overallRatingAvg;
      }

      setFreelancers(freelancers);
      sessionStorage.setItem("enh-freelancers", JSON.stringify(freelancers));
    } catch (error) {
      console.error("Error:", error);
    }
    setLoadingFls(false);
  };

  if (loadingForm) {
    return <CustomSpinner></CustomSpinner>;
  }

  if (!loadingForm && !formData) {
    setActiveStage(0);
  }

  return (
    <div className="flex flex-col  mt-4">
      <Sidebar
        proposalDetails={formData ? formData : ""}
        onRunMatch={onRunMatch}
        isMatchLoading={loadingFls}
        setActiveStage={setActiveStage}
      ></Sidebar>
      <div className="w-full my-4">
        <SimplifiedSearchResults
          freelancers={freelancers}
          isMatchLoading={loadingFls}
          setActiveStage={setActiveStage}
        />
      </div>
    </div>
  );
};

import React, { useContext, useState } from "react";

import BreadCrumbsBar from "./BreadCrumbsBar";
import ApplicationFeedbackForm from "./ApplicationFeedbackForm";
import { Avatar, Header } from "@paro.io/base-ui";
import { Auth0Context } from "../contexts/Auth0Context";
import { IconLogout, IconChat } from "@paro.io/base-icons";
import { Button as BaseUiButton } from "@paro.io/base-ui";
import { Menu } from "@headlessui/react";

import { Modal } from "react-bootstrap";
import CartDrawer from "./CartDrawer";
import BreadCrumbsBarNew from "./BreadCrumbsBarNew";

export default function TopSection(props) {
  const [showFeedback, setShowFeedback] = useState(false);
  const { user, logout } = useContext(Auth0Context);
  const USER_METADATA_URI = `${process.env.REACT_APP_USER_METADATA_URI}/user_metadata`;
  const { firstName, lastName } = user[USER_METADATA_URI];
  const username = firstName + " " + lastName;

  const { proposalId, showCart } = props;
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <>
      <Header
        reverseColor={true}
        className={"bg-primary"}
        size={"md"}
        title={props?.title ? props?.title : "Matching App"}
      >
        <div className="flex justify-between items-center">
          <div className="flex justify-end items-center">
            <span id="mm-appFeedback-open">
              <BaseUiButton
                onClick={() => setShowFeedback(true)}
                label="Send Feedback"
                iconLeft={<IconChat size="sm" />}
              />
            </span>
            <Menu as="div" className="relative inline-block text-left">
              <Menu.Button className="inline-flex justify-center px-2 mx-6 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                <Avatar name={username} src="https://bit.ly/broken-link" />
              </Menu.Button>
              <Menu.Items className="origin-top-right absolute left-0 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <Menu.Item onClick={() => logout()}>
                  {({ active }) => (
                    <a
                      href="#!"
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block text-sm"
                      )}
                    >
                      <BaseUiButton
                        label="Logout "
                        onClick={() => console.log("Logging Out")}
                        type="button"
                        color="secondary"
                        iconLeft={<IconLogout size="sm" />}
                      />
                    </a>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Menu>
            <Menu as="div" className="relative inline-block text-left">
              {showCart ? (
                <CartDrawer proposalId={proposalId} />
              ) : (
                <div>&nbsp;</div>
              )}
            </Menu>
          </div>
        </div>
      </Header>

      {/* <Avatar name="Avatar Name" size="md" src="https://picsum.photos/200" />
       */}
      {
        props?.new ? <div className="px-8 py-4"><BreadCrumbsBarNew {...props} /></div> : <BreadCrumbsBar {...props} />
      }
      {showFeedback && (
        <Modal
          show={showFeedback}
          size="lg"
          onHide={() => setShowFeedback(false)}
          centered
        >
          <ApplicationFeedbackForm setShowFeedback={setShowFeedback} />
        </Modal>
      )}
    </>
  );
}

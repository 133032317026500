import React, { useState } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import { makeStyles, TextField, FormLabel } from "@material-ui/core";
import SelectSoftware from "../SelectSoftware";
import SelectIndustry from "../SelectIndustry";
import SelectSkills from "../SelectSkills";
import { Heading } from "@paro.io/base-ui";
import { useTags } from "../../contexts/useTags";
import SelectLangOrCert from "../SelectLangOrCert";
import { Disclosure } from "@headlessui/react";
import { ChevronRightRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  textArea: {
    minWidth: "100%",
  },
}));

export default function EditProposalForm({ defaultValues }) {
  const [selectedSoftware] = useState(
    defaultValues["Required Software"] !== "Not Mentioned"
      ? defaultValues["Required Software"].split(",").map((software) => ({
          label: software.trim(),
          value: software.trim(),
        }))
      : []
  );
  const [selectedSkills] = useState(
    defaultValues["Required Skills"].map((skill) => ({
      label: skill,
      value: skill,
    }))
  );
  const { handleSubmit, control, register } = useForm({
    defaultValues: {
      ...defaultValues,
    },
    mode: "onChange",
  });

  const watchStartDateField = useWatch({
    control,
    name: "estimatedStartDate",
  });

  const {
    softwareOptions,
    industryOptions,
    skillOptions,
    certificationsOptions,
  } = useTags();

  const classes = useStyles();

  const onSubmit = async (data) => {
    // const formData = {
    //   ...data,
    // };
    // const requestData = {
    //   name: formData.name,
    //   description: formData.description,
    //   opportunityTypeMM: formData.opportunityTypeMM,
    //   pitchNotes: formData.pitchNotes,
    //   estimatedStartDate: formData.estimatedStartDate,
    //   estimatedEndDate: formData.estimatedEndDate,
    //   pitchRequired: formData?.pitchRequired,
    // };
    // const { data: responseData } = await updateProposal(
    //   defaultValues.id,
    //   requestData
    // );
    // error
    // if (
    //   responseData.errors &&
    //   responseData.errors[0].extensions?.code === "400"
    // ) {
    //   enqueueSnackbar("Something went wrong", {
    //     variant: "error",
    //   });
    //   return;
    // }
    // updateProposalFn();
  };

  return (
    <div className="flex rounded-md w-full">
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="off"
        className="w-full"
      >
        {
          <>
            <div className="grid grid-cols-1">
              <Heading size="h4">{defaultValues.client?.name}</Heading>
            </div>
            <div className="grid grid-cols-1">
              <Heading size="h5">Proposal Attributes</Heading>
            </div>

            <div>
              <i>
                Information on this page is Expert facing. It populates Expert
                emails and the Find Opportunities page
              </i>
            </div>
            <Disclosure defaultOpen>
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={`border mt-2 w-full  px-4 py-2 flex ${
                      open ? "rounded-t bg-gray-100" : "rounded"
                    }`}
                  >
                    <div className="w-full flex justify-between items-center">
                      <Heading size="h5">Proposal Information</Heading>
                      <ChevronRightRounded
                        className={open ? "rotate-90 transform" : ""}
                      />
                    </div>
                  </Disclosure.Button>
                  <Disclosure.Panel className={"border p-4 rounded-b"}>
                    <div className="pt-2">
                      <Controller
                        name="name"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <FormLabel className="my-2" component="legend">
                              Proposal Title*
                            </FormLabel>
                            <TextField
                              fullWidth
                              variant="outlined"
                              {...field}
                              error={!!error}
                              helperText={error ? error.message : null}
                            />
                          </>
                        )}
                        rules={{ required: "Proposal Title required" }}
                      />
                    </div>
                    <div className="grid grid-cols-1 my-2">
                      <Controller
                        name="description"
                        control={control}
                        defaultValue={defaultValues["Proposal Description"]}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <FormLabel className="my-2" component="legend">
                              Proposal Description*
                            </FormLabel>
                            <TextField
                              multiline
                              className={classes.textArea}
                              variant="outlined"
                              placeholder="Provide a Porposal Description"
                              {...field}
                              error={!!error}
                              helperText={error ? error.message : null}
                            />
                          </>
                        )}
                        rules={{ required: "Porposal Description required" }}
                      />
                    </div>
                    <div className="grid grid-cols-3 grid-flow-col gap-y-2 gap-x-2">
                      <div>
                        <Controller
                          name="estimatedStartDate"
                          fullWidth
                          rules={{ required: "Estimated Start Date required" }}
                          render={({ field, fieldState: { error } }) => (
                            <>
                              <FormLabel className="my-2" component="legend">
                                Est. Start Date*
                              </FormLabel>
                              <TextField
                                {...field}
                                fullWidth
                                id="estimatedStartDate"
                                type="date"
                                variant="outlined"
                                {...register("estimatedStartDate", {
                                  required: true,
                                })}
                                InputProps={{
                                  inputProps: {
                                    min: new Date(
                                      new Date().getTime() +
                                        3 * 24 * 60 * 60 * 1000
                                    )
                                      .toISOString()
                                      .split("T")[0],
                                    max: new Date(
                                      new Date().getTime() +
                                        365 * 24 * 60 * 60 * 1000
                                    )
                                      .toISOString()
                                      .split("T")[0],
                                  },
                                }}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            </>
                          )}
                          control={control}
                        />
                      </div>
                      <div>
                        <Controller
                          name="estimatedEndDate"
                          key={watchStartDateField}
                          fullWidth
                          rules={{ required: "Estimated End Date required" }}
                          render={({ field, fieldState: { error } }) => (
                            <>
                              <FormLabel className="my-2" component="legend">
                                Est. End Date
                              </FormLabel>
                              <TextField
                                {...field}
                                fullWidth
                                id="estimatedEndDate"
                                type="date"
                                variant="outlined"
                                {...register("estimatedEndDate", {
                                  required: true,
                                  validate: (estimatedEndDate) =>
                                    estimatedEndDate >= watchStartDateField,
                                })}
                                InputProps={{
                                  inputProps: {
                                    min:
                                      watchStartDateField ||
                                      new Date(
                                        new Date().getTime() +
                                          3 * 24 * 60 * 60 * 1000
                                      )
                                        .toISOString()
                                        .split("T")[0],
                                    max: new Date(
                                      new Date().getTime() +
                                        365 * 24 * 60 * 60 * 1000
                                    )
                                      .toISOString()
                                      .split("T")[0],
                                  },
                                }}
                                error={
                                  !!error || field.value < watchStartDateField
                                }
                                helperText={
                                  field.value < watchStartDateField
                                    ? "Estimated End Date should be after Estimated Start Date"
                                    : error
                                    ? error.message
                                    : null
                                }
                              />
                            </>
                          )}
                          control={control}
                        />
                      </div>
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>

            <Disclosure defaultOpen>
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={`border mt-2 w-full  px-4 py-2 flex ${
                      open ? "rounded-t bg-gray-100" : "rounded"
                    }`}
                  >
                    <div className="w-full flex justify-between items-center">
                      <Heading size="h5">Find Opportunity Requirements</Heading>
                      <ChevronRightRounded
                        className={open ? "rotate-90 transform" : ""}
                      />
                    </div>
                  </Disclosure.Button>
                  <Disclosure.Panel className={"border p-4 rounded-b"}>
                    <div className="grid grid-cols-2 grid-flow-col gap-y-2 gap-x-2">
                      <div>
                        <Controller
                          name="industries"
                          fullWidth
                          rules={{ required: "Industry required" }}
                          render={({ field, fieldState: { error } }) => (
                            <>
                              <FormLabel className="my-2" component="legend">
                                Industry*
                              </FormLabel>
                              <span id="mm-editProposal-industry">
                                <SelectIndustry
                                  {...field}
                                  options={industryOptions}
                                  defaultOptions={
                                    defaultValues.client?.firmographics
                                      ?.paroEquivalentClearbitIndustries
                                      ?.length > 0
                                      ? defaultValues.client?.firmographics?.paroEquivalentClearbitIndustries.map(
                                          (i) => ({
                                            field: "industries",
                                            value: i,
                                            required: false,
                                          })
                                        )
                                      : []
                                  }
                                  error={error}
                                  helperText={error ? error.message : null}
                                />
                              </span>
                            </>
                          )}
                          control={control}
                        />
                      </div>
                      <div>
                        <Controller
                          name="softwares"
                          fullWidth
                          rules={{ required: "Software required" }}
                          defaultValue={selectedSoftware}
                          render={({ field, fieldState: { error } }) => (
                            <>
                              <FormLabel className="my-2" component="legend">
                                Software*
                              </FormLabel>
                              <span id="mm-editProposal-software">
                                <SelectSoftware
                                  {...field}
                                  options={softwareOptions}
                                  error={error}
                                  helperText={error ? error.message : null}
                                />
                              </span>
                            </>
                          )}
                          control={control}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-1 my-4">
                      <Controller
                        name="skills"
                        fullWidth
                        defaultValue={selectedSkills}
                        render={({ field }) => (
                          <>
                            <FormLabel className="my-2" component="legend">
                              Skills
                            </FormLabel>
                            <span id="mm-editProposal-skill">
                              <SelectSkills {...field} options={skillOptions} />
                            </span>
                          </>
                        )}
                        control={control}
                      />
                    </div>

                    <div className="grid grid-cols-1 my-4">
                      <Controller
                        name="certificate"
                        fullWidth
                        render={({ field }) => (
                          <>
                            <FormLabel className="my-2" component="legend">
                              Certifications
                            </FormLabel>
                            <span id="mm-editProposal-certificate">
                              <SelectLangOrCert
                                {...field}
                                options={certificationsOptions}
                                placeholder="Certifications"
                              />
                            </span>
                          </>
                        )}
                        control={control}
                      />
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>

            {/* <div className="grid grid-cols-4 my-3">
              <span id="mm-editProposal-save">
                <Button
                  isLoading={formState.isSubmitting}
                  label={`Save`}
                  type="submit"
                  color="success"
                />
              </span>
            </div> */}
          </>
        }
      </form>
    </div>
  );
}

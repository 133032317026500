import React, { useEffect, useState } from "react";
import { Text, Modal, Button } from "@paro.io/base-ui";
import { Spinner } from "react-bootstrap";
import ReviewsModal from "../FindFreelancersUnifiedMatching/ReviewsModal";
import { SimplifiedAiResultCard } from "./SimplifiedAiResultCard";

export const SimplifiedSearchResults = ({
  freelancers,
  isMatchLoading,
  setActiveStage,
}) => {
  const [viewRating, setViewRating] = useState(false);
  const [ratingData, setRatingData] = useState({});
  const [cart, setCart] = useState([]);

  useEffect(() => {
    const cartFls = sessionStorage.getItem("CartFreelancers");
    if (cartFls) {
      setCart(JSON.parse(cartFls));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isMatchLoading) {
    return (
      <div className="border py-12 rounded-md flex items-center justify-center flex-col gap-4 bg-white my-2">
        <Spinner animation="border" variant="success" />
        <Text>Fetching Freelancers</Text>
      </div>
    );
  }

  if (!freelancers) {
    return (
      <Text className="flex font-bold border p-4 rounded-md bg-white align-middle justify-center my-2">
        Run match to find experts
      </Text>
    );
  }

  return (
    <>
      <div className="flex items-center justify-between gap-2 flex-wrap my-2">
        <div className="flex justify-between w-full">
          <div className="flex flex-wrap gap-3 items-center">
            <div className="p-2 border rounded">
              <svg
                width="24"
                height="24"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#a)" fill="#0D1017">
                  <path d="M9.1689 1.2689c0 .7011-.5678 1.2698-1.2689 1.2698s-1.2698-.5678-1.2698-1.2689S7.198 0 7.8991 0H7.9c.7007-.0005 1.2689.5678 1.2689 1.2689ZM7.5907 4.0117c0 .5637-.4571 1.0208-1.0208 1.0208-.5637 0-1.0208-.4571-1.0208-1.0208 0-.5637.457-1.0209 1.0208-1.0209.5641.0005 1.0208.4572 1.0208 1.0209ZM6.0883 6.9454c0 .532-.4309.9632-.9628.9632-.532 0-.9633-.4308-.9633-.9628s.4309-.9632.9628-.9632h.0005c.5315 0 .9628.4313.9628.9628ZM4.4466 9.8097a.836.836 0 0 1-.8354.8363.836.836 0 0 1-.8362-.8354.836.836 0 0 1 .8353-.8363h.0005c.4617 0 .8358.3742.8358.8354ZM2.647 12.5951a.6296.6296 0 0 1-.6294.6299.6296.6296 0 0 1-.6299-.6294.6295.6295 0 0 1 .6295-.6299h.0004c.3474 0 .6295.282.6295.6294 0 .0005 0 .0005 0 0ZM5.755 11.5965a.6296.6296 0 0 1-1.259 0c0-.3474.282-.6295.6295-.6295a.6293.6293 0 0 1 .6294.6295ZM1.0426 15.478A.5212.5212 0 1 1 0 15.4794a.5212.5212 0 0 1 .5206-.522h.001c.2879 0 .521.2336.521.5211M8.2243 4.0049c0 .5596.454 1.0136 1.0135 1.0136.5597 0 1.0136-.454 1.0136-1.0136 0-.5596-.454-1.0136-1.0136-1.0136-.5596 0-1.0135.454-1.0135 1.0136ZM9.7118 6.9454c0 .532.4308.9632.9627.9632.532 0 .9633-.4308.9633-.9628s-.4309-.9632-.9628-.9632h-.0005c-.5315 0-.9627.4313-.9627.9628ZM11.3534 9.8097a.836.836 0 0 0 .8354.8363.836.836 0 0 0 .8362-.8354c.0005-.4617-.3737-.8358-.8353-.8363h-.0005c-.4616 0-.8358.3742-.8358.8354ZM8.452 9.4492a.7314.7314 0 1 0 1.463 0 .7314.7314 0 0 0-.7316-.7315.7314.7314 0 0 0-.7315.7315ZM6.949 6.7812a.9501.9501 0 1 0 1.9002 0 .9501.9501 0 0 0-1.9002 0ZM5.8856 9.4492a.7314.7314 0 1 0 1.463 0 .7314.7314 0 0 0-.7316-.7315.7314.7314 0 0 0-.7314.7315ZM13.1516 12.5951c0 .3478.2816.6299.6294.6299a.6295.6295 0 0 0 .6299-.6294.6296.6296 0 0 0-1.2593-.0014v.0009ZM10.0451 11.5965a.6295.6295 0 1 0 .6294-.6295.629.629 0 0 0-.6294.6295ZM14.7574 15.478a.5213.5213 0 1 0 .522-.5206h-.0009a.5212.5212 0 0 0-.5211.5211M7.9 10.5788a.6295.6295 0 1 0 .0001 1.259.6295.6295 0 0 0 0-1.259Z"></path>
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#0D1017" d="M0 0h16v16H0z"></path>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <h3 className="font-2xl text-success font-bold ">
              {freelancers.length}{" "}
              {freelancers.length === 1 ? "Expert" : "Experts"} Recommended
            </h3>
          </div>
        </div>
      </div>
      {freelancers?.length > 0 ? (
        <div className="w-full px-4 bg-white border border-gray-200 rounded-lg shadow">
          <div className="flow-root">
            <ul className="divide-y divide-gray-200 list-none">
              {freelancers.map((freelancer, idx) => (
                <SimplifiedAiResultCard
                  freelancer={freelancer}
                  key={idx}
                  onViewReview={(data) => {
                    setViewRating(!viewRating);
                    setRatingData(data);
                  }}
                  cart={cart}
                  setCart={setCart}
                />
              ))}
            </ul>
          </div>
        </div>
      ) : null}
      <div className="flex justify-end">
        <Button
          label="Confirm"
          className="mt-3"
          onClick={() => setActiveStage(2)}
        />
      </div>
      {/* Reviews Modal */}
      <Modal
        label=""
        variant={"passive"}
        color="primary"
        open={viewRating}
        onClose={() => setViewRating(false)}
        icon="danger"
        size="md"
      >
        <ReviewsModal freelancer={ratingData} />
      </Modal>
    </>
  );
};

import React, { useState } from "react";
import { Button, Text } from "@paro.io/base-ui";
import Select from "react-select";
import { useTags } from "../../contexts/useTags";

const colourStyles = (errors) => ({
  control: (styles) => ({
    ...styles,
    backgroundColor: "#f1f5f9",
    border: errors ? "1px solid #f17175 !important" : "1px solid #ddd",
    ...(errors && { boxShadow: "none" }),
  }),
  multiValue: (styles) => {
    const color = "#fff";
    return {
      ...styles,
      backgroundColor: color,
      color: "black",
      border: "1px solid #ddd",
      borderRadius: "14px",
      textWrap: "wrap",
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: "black",
    borderRadius: "16px",
    textWrap: "wrap",
  }),
});

const Sidebar = ({
  proposalDetails,
  onRunMatch,
  isMatchLoading,
  setActiveStage,
}) => {
  const { softwareOptions, industryOptions, skillOptions } = useTags();

  const proposal = { ...proposalDetails?.generatedSummary };

  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [selectedSoftware, setSelectedSoftware] = useState(
    proposal["Required Software"] !== "Not Mentioned"
      ? proposal["Required Software"]?.split(",").map((software) => ({
          label: software.trim(),
          value: software.trim(),
        }))
      : []
  );
  const [selectedSkills, setSelectedSkills] = useState(
    proposal["Required Skills"]?.map((skill) => ({
      label: skill,
      value: skill,
    }))
  );

  const handleChange = (setter) => (selectedOptions) => {
    setter(selectedOptions || []);
  };

  const handleMatch = () => {
    const payload = {
      notes: proposalDetails.fieldText,
      software:
        selectedSoftware?.map((software) => software.value).join(", ") || "",
      industry:
        selectedIndustry?.map((industry) => industry.value).join(", ") || "",
      fasttrackServices: proposal["Required Services"]?.join(", ") || "",
    };
    onRunMatch(payload);
  };
  return (
    <div>
      <div
        className={`border rounded-md bg-white px-3 `}
        style={{
          maxHeight: "79vh",
          overflowX: "hidden",
        }}
      >
        <div>
          <Text>Solution</Text>
          <Select isMulti styles={colourStyles()} isClearable={false} />
        </div>
        <div className="mt-3">
          <Text>Industry</Text>
          <Select
            options={industryOptions.map((industry) => ({
              label: industry,
              value: industry,
            }))}
            isMulti
            styles={colourStyles()}
            isClearable={false}
            value={selectedIndustry}
            onChange={handleChange(setSelectedIndustry)}
          />
        </div>
        <div className="mt-3">
          <Text>Software</Text>
          <Select
            options={softwareOptions.map((software) => ({
              label: software,
              value: software,
            }))}
            isMulti
            isClearable={false}
            styles={colourStyles()}
            value={selectedSoftware}
            onChange={handleChange(setSelectedSoftware)}
          />
        </div>
        <div className="mt-3 mb-4">
          <Text>Skills</Text>
          <Select
            options={skillOptions.map((skill) => ({
              label: skill,
              value: skill,
            }))}
            isMulti
            styles={colourStyles()}
            isClearable={false}
            value={selectedSkills}
            onChange={handleChange(setSelectedSkills)}
          />
        </div>
      </div>
      <div className="flex flex-row justify-between items-center mt-3">
        <Button
          label="Back"
          onClick={() => setActiveStage(0)}
          size="md"
        ></Button>
        <Button
          label="match"
          size="md"
          onClick={handleMatch}
          isLoading={isMatchLoading}
        />
      </div>
    </div>
  );
};

export default Sidebar;

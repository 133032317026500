import { Box, Grid, Paper } from "@material-ui/core";
import { Button, Heading } from "@paro.io/base-ui";
import { useEffect, useState } from "react";
import EditProposalForm from "./EditProposalForm";
import CustomSpinner from "../CustomSpinner";
import { ErrorOutline } from "@material-ui/icons";
import { SimplifiedAiResultCard } from "./SimplifiedAiResultCard";

const ConfirmProject = ({
  setActiveStage,
  setFieldText,
  setGeneratedSummary,
}) => {
  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState(true);
  const [cart, setCart] = useState([]);

  useEffect(() => {
    setLoading(true);
    const data = sessionStorage.getItem("enh-proposal");
    const cartFls = sessionStorage.getItem("CartFreelancers");
    if (data) {
      setFormData(JSON.parse(data));
    }
    if (cartFls) {
      setCart(JSON.parse(cartFls));
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <CustomSpinner></CustomSpinner>;
  }

  // const onConfirmRemoveFromCartFn = (freelancer) => {
  //   setCart((currentState) =>
  //     currentState.filter((c) => c.flID !== freelancer.id)
  //   );
  //   const currentCart =
  //     JSON.parse(sessionStorage.getItem("CartFreelancers")) || [];
  //   const updatedCart = currentCart.filter((c) => c.flID !== freelancer.id);
  //   sessionStorage.setItem("CartFreelancers", JSON.stringify(updatedCart));
  //   enqueueSnackbar(`Removed ${freelancer.name} from Cart!`, {
  //     variant: "warning",
  //   });
  // };
  return (
    <div className="flex flex-col w-full">
      <div className="">
        <EditProposalForm
          defaultValues={formData ? formData.generatedSummary : ""}
        />
      </div>
      <div className="flex w-full py-4">
        <Box className="justify-between w-full">
          <Heading size="h5">
            You have selected {cart.length} of 3 freelancers.
          </Heading>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {cart.length > 3 && (
                <Paper
                  variant="outlined"
                  style={{
                    backgroundColor: "#fff3cd",
                    padding: "8px",
                    display: "flex",
                    alignItems: "center",
                    marginTop: "8px",
                    marginBottom: "8px",
                  }}
                >
                  <ErrorOutline />
                  <span className="ml-2">
                    Please submit only 3 freelancers at the time
                  </span>
                </Paper>
              )}

              {cart?.length > 0 && (
                <div className="w-full px-4 py-2 bg-white border border-gray-200 rounded-lg shadow">
                  <div className="flow-root">
                    <ul className="divide-y divide-gray-200 list-none">
                      {cart.map((freelancer, idx) => (
                        <SimplifiedAiResultCard
                          freelancer={freelancer}
                          key={idx}
                          cart={cart}
                          setCart={setCart}
                          stage3={true}
                        />
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </Box>
      </div>
      <div className="flex justify-between mb-4">
        <Button label="back" className="" onClick={() => setActiveStage(1)} />
        <Button
          label="Submit"
          disabled={cart.length > 3}
          onClick={() => {
            sessionStorage.removeItem("enh-proposal");
            sessionStorage.removeItem("CartFreelancers");
            sessionStorage.removeItem("enh-freelancers");
            setActiveStage(0);
            setFieldText("");
            setGeneratedSummary(null);
          }}
        ></Button>
      </div>
    </div>
  );
};

export default ConfirmProject;
